.common-strategy-overview-outer-container {
  display: flex;
  flex-direction: column;
  * {
    //make non-highlightable
    user-select: none;
  }

  .common-strategy-overview-header-container {
    height: 20px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;
  }
}

.common-strategy-overview-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  flex: 1;
  min-width: 560px;
  overflow-x: hidden;
  overflow-y: auto;
  gap: 10px;
  padding: 10px;
  margin-top: 3px;
  padding-bottom: 20px;
  min-height: 380px;

}

.common-strategy-overview-footer-container {
  width: 100%;
  height: 100px;
}
.common-strategy-overview-loader-container {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 100%;
  flex: 1;
  margin-top: 200px;
}
