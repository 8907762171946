.tutorial-tracker {
  display: flex;
  position: fixed;
  min-height: 60px;
  width: 300px;
  backdrop-filter: blur(20px);
  z-index: 9999;
  padding: 6px;
  border-radius: 6px;
  flex-direction: column;
  .tutorial-tracker-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
  }
}

.tutorial-complete-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  .tutorial-icon-container {
    max-height: 120px;
    max-width: 120px;
  }
}