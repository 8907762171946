.common-control-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  .empty-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
}

.backtester-control-column-switch {
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  display: flex;


  .common-control-column-header {
    &.switch {
      padding: 5px;
      cursor: pointer;
      * {
        cursor: pointer;
      }
    }
  }
}

.tournament-mode-label {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .tournament-label {
    font-size: larger;
    opacity: 0.8;
    margin-left: 10px;
  }
  .countdown-label {
    font-size: 20px;
    margin-right: 10px;
  }
}