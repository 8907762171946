$squarew: 25px;
$squareh: 7px;
$squaremargin: 35px;
$loaderw: $squarew * 3 + $squaremargin * 2;
$loaderh: $squareh * 3 + $squaremargin * 2;
$delayenter: 0.1s;
$topenter: -10px;
$animationDuration: 2.7s;

@mixin transition($value) {
  -webkit-transition: $value;
  -moz-transition: $value;
  transition: $value;
}

@mixin delay($delay) {
  -webkit-animation-delay: $delay;
  -moz-animation-delay: $delay;
  animation-delay: $delay;
}

@keyframes enter {
  0% {
    opacity: 0;
    top: 0px;
    transform: rotation(90);
  }
  5% {
    opacity: 1;
    top: $topenter;
  }

  40.9% {
    opacity: 1;
    margin-right: 0px;
  }
  66.9% {
    margin-right: 0px;
  }
  90% {
    opacity: 0;
    margin-right: 0px;
  }
}

@keyframes merge {
  26% {
    opacity: 1;
  }

  76.9% {
    gap: 0px;
    opacity: 0.3;
  }

  76.9% {
    gap: 0px;
  }
}

body {
  background: transparent;
}
* {
  margin: 0;
}

.row {
  opacity: 0.5;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0px;
  animation: merge $animationDuration infinite;
}

.loader {
  display: flex;
  flex-direction: column;
  margin-top: -$loaderh / 2;
  align-self: center;
}
.square {
  background: var(--color);
  width: $squarew;
  height: $squareh;
  float: left;
  top: $topenter;
  margin-top: $squaremargin;
  transition: 0.2s ease all;
  position: relative;
  opacity: 0;
  -webkit-animation: enter $animationDuration infinite;
  animation: enter $animationDuration infinite;
}
.enter {
  top: 0px;
  opacity: 1;
}
#s1 {
  @include delay(var(--s1-delay));
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
#s2 {
  @include delay(var(--s2-delay));
}
#s3 {
  @include delay(var(--s3-delay));
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
#s4 {
  @include delay(var(--s4-delay));
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
#s5 {
  @include delay(var(--s5-delay));
}
#s6 {
  @include delay(var(--s6-delay));
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
#s7 {
  @include delay(var(--s7-delay));
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
#s8 {
  @include delay(var(--s8-delay));
}
#s9 {
  @include delay(var(--s9-delay));
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.clear {
  clear: both;
}
.last {
  margin-right: 0;
}
