.icon-button,
.icon-button-disabled {
  transition: 0.3s ease all;
  margin: 0;
  padding: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 10%;
}

.icon-button {
  cursor: pointer;
  * {
    cursor: pointer;
  }
}