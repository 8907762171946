.currency-array-item {
  transition: 0.14s ease all;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 2px solid transparent;
  border-radius: 50%;
  padding: 1px;
  &.holding {
    filter: saturate(3)
  }
  &.buy, &.sell {
    animation: toSubtleSaturation 2s infinite alternate ease-in-out;
  }
}
