.page-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  transition: ease all 0.2s;
  overflow: hidden;
}

.upper-row,
.lower-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.lower-row {
  height: 100%;
  max-height: 89vh;
  padding-bottom: 5px;
}
.upper-row {
  max-height: 25vh;
  height: fit-content;
}

.upper-row {
  @media screen and (max-width: 1368px), screen and (max-height: 900px) {
    height: fit-content;
  }
}

.dirty-indicator-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 30%;
  .dirty-indicator,
  .dirty-indicator-active {
    transition: ease all 0.5s;
    border: 1px solid transparent;
    border-radius: 100%;
    height: 5px;
    width: 5px;
  }
}

.dirty-distribution-indicator-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  .dirty-indicator,
  .dirty-indicator-active {
    transition: ease all 0.5s;
    border: 1px solid transparent;
    border-radius: 100%;
    height: 5px;
    width: 5px;
  }

  .rank-menu-container {
    display: flex;
    align-items: flex-end;
    @media screen and (max-width: 1368px) and (max-height: 900px) {
      align-items: center;
    }
  }
}

.dirty-price-head-indicator-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .dirty-indicator,
  .dirty-indicator-active {
    transition: ease all 0.5s;
    border: 1px solid transparent;
    border-radius: 100%;
    height: 5px;
    width: 5px;
  }

  .rank-menu-container {
    display: flex;
    align-items: flex-end;
  }
}

.wallet-container,
.center-container,
.info-container {
  border-radius: 2px;
}
.wallet-container,
.strategy-upper-container {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 4.5rem;
  width: 100%;
  height: 21.5vh;
  min-height: 100px;
  @media screen and (max-height: 900px) {
    min-height: 50px;
  }
}

.wallet-container {
  min-height: 220px;
}

.center-container {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 4.5rem;
  width: 75%;
  height: 76vh;
}
.info-container {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  width: 25%;
  height: 76vh;
}

.strategy-center-container {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 4.5rem;
  width: 100%;
  min-height: 1000px;
  height: 76vh;
  @media screen and (max-height: 900px) {
    height: 50vh;
  }
}

@media screen and (max-width: 1368px) {
  .center-container-active {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    margin-left: 4.5rem;
    width: 80%;
    height: 75vh;
  }
  .center-container {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    margin-left: 4.5rem;
    width: 20%;
    height: 75vh;
  }
  .info-container-active {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    width: 80%;
    height: 75vh;
  }
  .info-container {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    width: 20%;
    height: 75vh;
  }
}

.wallet-stat-label {
  font-size: smaller;
  cursor: default;
  font-family: "IBMPlexMono-Medium";
  transition: 0.2s ease all;
}
