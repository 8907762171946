.common-event-log {
  display: flex;
  overflow: hidden;
  height: 100%;
  &.detached {
    min-height: 600px;
  }

  .common-trading-event-container {
    transition: 0.2s ease all;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    .common-trading-event-header-container {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 3px;
    }

    .pool-composition-event {
      width: 100%;
      min-height: 50px;
      height: fit-content;
      display: flex;
      .pool-event-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        .pool-event-header {
          display: flex;
        }
        .pool-event-body {
          display: flex;
          flex-wrap: wrap;
          height: fit-content;
        }
      }
    }
  }
}
