.quickstart-guide-container {
  position: absolute;
  width: 100%;
  flex: 1 1;
  margin-top: 40px;
  height: 80%;
  padding-bottom: 6px;
  padding-left: 6px;
  padding-right: 6px;
  z-index: 98;
  
  .quickstart-guide {
    backdrop-filter: blur(10px);
    height: 100%;
    border-radius: 6px;
    position: relative;
    display: flex;
    gap: 10px;
    padding-left: 10px;
    padding-right: 10px;
    overflow-y: hidden;
    overflow-x: auto;
    
    .quickstart-guide-step {
      transition: .2s ease all;
      padding: 10px;
      display: flex;
      flex-direction: column;
      height: 80%;
      .quickstart-guide-step-header {
        font-size: larger;
      }

      .quickstart-guide-step-description {
        font-size: small;
        opacity: .8;
        .dimmed-label {
          white-space: wrap;
        }
      }
    }
  }
}
