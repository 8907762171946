.achievements-container {
  flex: 1;
  min-height: 250px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .achievements-header-label {
    align-self: center;
    font-size: smaller;
    display: flex;
    align-items: center;
    gap: 4px;
    opacity: 0.6;
    transition: 0.2s ease all;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
}
.achievements-content {
  min-width: 250px;
  max-width: 450px;
  height: 150px;
  margin-top: 20px;
  margin-bottom: 20px;
  &.compact {
    min-width: 100px;
    max-width: 200px;
  }
}

.user-achievements-outer {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  .loading-achievements-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .user-achievements {
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    scroll-behavior: smooth;
    scrollbar-width: none;
    gap: 20px;
    flex: 1;
    &.compact {
      gap: 4px;
    }
    .achievement-container {
      padding-left: 20px;
      padding-right: 20px;
      &.compact {
        padding-left: 8px;
        padding-right: 8px;
      }
      &:first-child {
        padding-left: 30px;
      }
      &:last-child {
        padding-right: 30px;
      }
      // make not selectable and draggable
      user-select: none;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: visible;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      cursor: pointer;
      * {
        cursor: pointer;
      }
      &:hover {
        .achievement-label-container {
          transition: 0.2s ease all;
          bottom: 2%;
        }
      }
      .achievement-label-container {
        transition: 0.2s ease all;
        position: absolute;
        z-index: 99;
        height: 10px;
        width: 10px;
        bottom: 6%;
        display: flex;
        align-items: center;
        justify-content: center;
        .achievement-label {
          border-radius: 12%;
          padding: 2px;
          padding-left: 4px;
          padding-right: 4px;
          backdrop-filter: blur(4px);
          -webkit-backdrop-filter: blur(4px);
          font-size: small;
          font-family: Montserrat-LightAlt;
          font-weight: 600;
        }
      }
    }
  }
}

.achievement-details-container {
  display: flex;
  flex-direction: column;
  max-width: 190px;
  .achievement-header-label {
    font-family: Montserrat-LightAlt;
    font-weight: 400;
    opacity: 0.8;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  .achievement-description-label {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: smaller;
    opacity: 0.7;
  }
}

.achievements-arrow-scroll {
  position: absolute;
  transition: 0.2s ease all;
  cursor: pointer;
  * {
    cursor: pointer;
  }
  height: 98%;
  border-radius: 4px;
  border: 1px solid transparent;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
}

.no-achievements-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
