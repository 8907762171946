.order-inspect-container {
  display: flex;
  flex-direction: column;
  font-size: smaller;
  border-radius: 5px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 10px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 90lvh;
  .order-inspect-case {
    font-size: larger;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 4px;
  }
  .order-inspect-body {
    display: flex;
    align-items: center;
    height: 200px;
    width: 100%;
  }
}

.trade-stats-container {
  display: flex;
  // media query
  @media (max-width: 768px) {
    flex-direction: column;
  }

  .buy-order-stat-container,
  .sell-order-stat-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    height: auto;
    flex: 1;
    @media (max-width: 768px) {
      border-radius: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}



.order-stat-row {
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 4px;
  width: 100%;
  justify-content: space-between;
  max-height: 25px;
  min-height: 25px;
  align-items: center;
}

.currency-accent {
  height: 50px;
  width: 50px;
  position: absolute;
  filter: blur(80px);
  top: 20%;
  @media (max-width: 768px) {
    top: 30%;
    height: 60%;
    filter: blur(120px);

  }
}
.trade-accent {
  height: 110px;
  width: 110px;
  right: 1%;
  position: absolute;
  filter: blur(90px);
 
}

.trade-reason-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
  height: auto;
  width: 100%;
  
  .reason-header {
    transition: .2s ease all;
    display: flex;
    width: 100%;
    align-items: center;
    gap: 5px;
    border: 1px solid transparent;
    cursor: pointer;
    * {
      cursor: pointer;
    }
  }
  .reason-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-height: 400px;
    overflow-y: auto;
    &.immediate-action-reason-container{
      display: flex;
      flex-direction: column;
      max-width: 100%;
      border-radius: 4%;
      padding: 4px;
    
    }

    .immediate-action-reason-row {
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: center;
      .immediate-action-type-label {
        text-transform: capitalize;
      }
      
    }

    .criteria-candle-size-reason-container {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      border-radius: 4%;
      padding: 4px;
    
      .candle-size-reason-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        padding: 4px;
        max-width: 100%;
        height: 100%;
      }
    }
  }

}


.equation-wrapper-smaller {
  user-select: none;
  font-size: smaller;
}