.papertrader-leaderboard-container {
  display: flex;
  height: 100%;
  overflow-y: auto;
  &.no-scroll {
    overflow-y: hidden;
  }
  padding: 4px;
  overflow-x: hidden;
  flex-wrap: wrap;
  gap: 10px;
  align-content: flex-start;
  border-radius: 10px;
  @media (max-width: 768px) {
    width: 99%;
  }

  .papertrader-leaderboard-item-container {
    position: relative; // <-- Add this to establish a new positioning context for children
    height: 120px;
    max-width: 520px;
    &.compact {
      height: 80px;
      font-size: smaller;
    }
    &.homepage {
      max-width: none;
    }
    min-width: 420px;
    flex: 1;
    @media (max-width: 768px) {
      min-width: 50lvw;
      max-width: 90lvw;
      width: 90%;
    }
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    transition: 0.2s ease-in-out;
  }

  .leaderboard-item-middle-section {
    display: flex;
    flex-direction: column;
    flex: 1;
    @media (max-width: 768px) {
      flex: 1;
      max-width: 90%;
    }
    .leaderboard-item-compact-currency-and-tier {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 0;
      height: 90%;
      gap: 2px;
      padding: 2px;
      align-items: flex-end;
      &.winning {
        justify-content: flex-end;
      }
      &.losing {
        flex-direction: column-reverse;
        justify-content: flex-end;
      }
    }

    .leaderboard-wallet-value-container {
      display: flex;
      height: 100%;
      .leaderboard-item-chart-container {
        display: flex;
        width: 100%;

        .leaderboard-item-compact-title-container {
          white-space: nowrap;
          display: flex;
          align-items: center;
          position: absolute;
          backdrop-filter: blur(2px);
          border-radius: 2px;
          z-index: 5;
          gap: 2px;
          label {
            &.dimmed-label {
              font-size: x-small !important;
              &.rank {
                font-size: smaller !important;
                opacity: 0.8;
              }
            }
          }
        }
      }
      .leaderboard-pct-container {
        font-size: small;
        min-width: 40px;
        max-width: 80px;
        align-items: center;
        cursor: default;
        justify-content: flex-end;
        margin-right: 5px;
        display: flex;

        &.winning {
          align-items: flex-start;
          margin-top: 10px;
        }
        &.losing {
          align-items: flex-end;
          margin-bottom: 10px;
        }
      }
    }

    .leaderboard-item-currency-array {
      display: flex;
      height: 20px;
      gap: 5px;
      align-items: center;
      margin-bottom: 4px;
      overflow-y: visible;
      justify-content: space-between;
      padding: 4px;

      .leaderboard-item-tier-badges {
        display: flex;
      }
    }

    .leaderboard-strategy-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2px;
      label {
        font-size: smaller !important;
        font-family: Montserrat-LightAlt !important;
      }

      .leaderboard-item-strategy-name-label {
        max-width: 90%;
        flex: 1;
        display: flex;
        align-items: center;
        opacity: 0.7;
        font-weight: 600;
        text-transform: capitalize;
        &.owned {
          opacity: 1;
          font-weight: 600;
        }
      }

      .dimmed-label {
        font-size: small;
        &.best-version {
          margin-left: auto;
        }
      }
    }
  }

  .leaderboard-profile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    .leaderboard-profile-picture-container {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 4px;
    }

    // add box shadow to children
  }
}

.rank-label {
  transition: 0.2s ease-in-out;
  margin-left: auto;
  margin-right: 5px;
  letter-spacing: 1px;
  font-weight: 600;
}

.leaderboard-item-block {
  display: flex;
  align-items: center;
  padding: 2px;
  gap: 10px;
  box-shadow:
    rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;
  padding-left: 4px;
  padding-right: 4px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  height: 100%;
  &.rank {
    padding-top: 0;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 2px;
    align-items: flex-start;
    max-height: 35px;
    .rank-item-inner {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding-left: 4px;
      padding-right: 4px;
      display: flex;
      align-items: center;
      height: 100%;
      gap: 5px;
    }
    &.rank-1 {
      background: radial-gradient(
          ellipse farthest-corner at right bottom,
          #fedb37 0%,
          #fdb931 8%,
          #9f7928 30%,
          #8a6e2f 40%,
          transparent 80%
        ),
        radial-gradient(
          ellipse farthest-corner at left top,
          #ffffff 0%,
          #ffffac 8%,
          #d1b464 25%,
          #5d4a1f 62.5%,
          #5d4a1f 100%
        );
    }
    &.rank-2 {
      background: radial-gradient(
          ellipse farthest-corner at right bottom,
          #e0e0e0 0%,
          #bfbfbf 8%,
          #a3a3a3 30%,
          #8a8a8a 40%,
          transparent 80%
        ),
        radial-gradient(
          ellipse farthest-corner at left top,
          #ffffff 0%,
          #e6e6e6 8%,
          #cccccc 25%,
          #b3b3b3 62.5%,
          #999999 100%
        );
    }
    &.rank-3 {
      background: radial-gradient(
          ellipse farthest-corner at right bottom,
          #cd7f32 0%,
          #c77826 8%,
          #a55d1e 30%,
          #8b4c15 40%,
          transparent 80%
        ),
        radial-gradient(
          ellipse farthest-corner at left top,
          #ffe4c4 0%,
          #d2a679 8%,
          #b87333 25%,
          #8a5a1d 62.5%,
          #5c4033 100%
        );
    }
  }
}
