.case-selection-container {
  display: flex;
  flex-direction: column;
  padding: 4px;
  border-radius: 6px;
  gap: 10px;

  .add-case-container, .case-item {
    display: flex;
    flex-direction: row;
    gap: 4px;
    padding: 6px;
    align-items: center;
    border-radius: 6px;
  }
  .case-item {
    width: 100%;

    .case-features-container {
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      padding-right: 5px;
    }

  }

  .existing-cases-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 6px;
    padding: 4px;

  }

}
