.desktop-homepage-container {
  @media screen and (min-width: 800px) {
    width: 93%;
  }
  @media screen and (min-width: 900px) {
    width: 95%;
  }
  @media screen and (min-width: 1155px) {
    width: 96%;
  }
  @media screen and (min-width: 1355px) {
    width: 97%;
  }
  @media screen and (min-width: 1920px) {
    width: 100%;
  }
  height: 99lvh;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: wrap;
  z-index: 2;
  padding-top: 0.5rem;
  overflow: hidden;
  &.narrow {
    overflow-y: auto;
  }
}

.homepage-movable-card {
  cursor: default;
  transition: 0.2s ease all;
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  flex: 1;
  flex-direction: column;
  min-height: 30px;
  &.minimized {
    flex: 1;
  }
  &:hover {
    .homepage-movable-card-header-container {
      .dimensions-button-row {
        opacity: 1;
      }
    }
  }

  .homepage-movable-card-header-container {
    transition: 0.2s ease all;
    transition-delay: 0.2s;
    padding-left: 4px;
    padding-left: 8px;
    border-radius: 6px;
    border: 1px solid transparent;
    height: 40px;
    display: flex;
    width: 99%;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    gap: 5px;
    position: relative;
    
    .dimensions-button-row {
      transition: 0.2s ease all;
      transition-delay: 0.2s;
      gap: 5px;
      opacity: 0;
      display: flex;
      height: 100%;
      align-items: center;
      margin-left: auto;
      margin-right: 10px;
    }

    .homepage-movable-card-header-icon {
      transition: 0.2s ease all;
      height: 100%;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.4;
      cursor: pointer;
      * {
        cursor: pointer;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}

.video-tutorials-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .tutorial-titles-container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    padding: 10px;
    flex-wrap: wrap;
    .tutorial-title-item {
      transition: 0.2s ease all;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 10px;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    }
    cursor: pointer;
    * {
      cursor: pointer;
    }
  }
}

.desktop-card-loader {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  backdrop-filter: blur(5px);
}
.modal-card-container {
  max-height: 80lvh;
  overflow-y: auto;
  overflow-x: hidden;
}

.homepage-movable-card-drag-handle {
  transition: .2s ease all;
  position: absolute;
  font-size: larger;
  left:40%;
  width: 150px;
  z-index: 100;
  align-items: center;
  display: flex;
  justify-content: center;
  top: 1%;
  height: 50px;
  gap: -1px;
}