.compact-livetrader-control-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  .compact-livetrader-control {
    width: 100%;
    height: fit-content;
    align-items: center;
    display: flex;
    gap: 5px;

    .strategy-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 5px;
      .strategy-section-row {
        display: flex;
        flex-direction: row;
      }
    }
  }
  .livetrader-compact-account-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: flex-end;
    .compact-livetrader-exchange-icon {
      height: 40px;
      width: 40px;
    }
  }
}

.livetrader-circular-progress-bar-outer-container {
  flex: 1;
  max-width: 80%;
  display: flex;
  font-size: medium;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-direction: column;
  
  .execution-label-container {
    display: flex;
    flex-direction: column;

    gap: 5px;
    align-items: center;
  }

  &.compact {
    font-size: smaller;
    flex-direction: column;
    .execution-label-container {
      align-items: flex-start;
    }
  }
  .livetrader-circular-progress-bar-container {
    height: 30px;
    width: 30px;
  }
}

.common-control-column-content {
  height: 50px;
  flex-direction: row;
  display: flex;
  align-items: center;
}
.livetrader-exchange-icon {
  max-width: 130px;
  padding: 8px;
}


.execution-message-container {
  overflow: hidden;
  height: 20px;
  width: 100%;
  
  .execution-message {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  #marquee-message {
    animation: marquee 20s linear infinite;
    @media (max-width: 768px) {
      animation: marquee 30s linear infinite;
    }
  }
}

.circular-progress-icon-container {
  display: flex;
  align-items: center;
}

