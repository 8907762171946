.code-fragments-overview-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  max-height: 450px;
  overflow-y: auto;
  gap: 10px;
  overflow-x: hidden;
  position: relative;
  flex: 1;
  height: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow:
    rgba(0, 0, 0, 0.07) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset;

  .top-right-container {
    position: absolute;
    top: 1%;
    right: 1%;
    width: 280px;
    padding: 4px;
    border-radius: 8px;
  }
  .code-fragments-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 2px;
    width: 99%;

    .code-fragment-row-container {
      width: 100%;
    }
  }
  .code-fragment-container {
    transition: 0.2s ease all;
    display: flex;
    padding: 2px;
    width: fit-content;
    &.operator {
      font-size: larger;
    }
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
    cursor: pointer;
    * {
      cursor: pointer;
    }
  }
  .ta-indicator-code-pair {
    display: flex;
    align-items: center;
  }
}
.directions-popup-body,
.directions-popup-inner,
.directions-popup-wrapper {
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);

  background-color: transparent !important;
  * {
    background-color: transparent;
  }
}

.custom-number-input-container {
  display: flex;
  align-items: center;
  gap: 5px;
  .custom-number-input {
    font-size: larger;
    border-radius: 4px;
    font-family: "IBMPlexMono-Medium";
    text-align: left;

    outline: none;
    transition: border-color 0.3s;
    width: 100%;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.error-text-container {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-direction: column;
  align-items: flex-start;
  .error-text {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
    width: 99%;
  }
}

.code-fragment-pattern-outer-container {
  transition: 0.2s ease all;
  .code-fragment-pattern-outer-header-container {
    display: flex;
    align-items: center;
    gap: 10px;
    .code-fragment-pattern-outer-header-label {
      font-size: smaller;
      opacity: 0.9;
      padding: 3px;
    }
  }
  .code-fragment-pattern-container {
    transition: 0.2s ease all;
    display: flex;
    flex-direction: column;
    &:hover {
      .code-fragment-pattern-header-container {
        .hide-show-label {
          transition-delay: 0.4s;
          opacity: 0.8;
        }
      }
    }
    .code-fragment-pattern-header-container {
      display: flex;
      position: relative;
      .hide-show-label {
        position: absolute;
        right: 2%;
        font-size: smaller;
        cursor: pointer;
        opacity: 0;
        transition: 0.2s ease all;
      }
    }
  }
}

.code-fragments-details-container {
  padding: 4px;
  display: flex;
  flex: 1;
}
.code-fragment-label-button {
  margin-left: auto;
  margin-right: 10px;
  font-size: smaller;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.2s ease all;
  &:hover {
    opacity: 1;
  }
}

.dimmed-label {
  &.info {
    padding: 4px;
  }
  &.description {
    white-space: wrap;
    max-width: 50%;
  }
}
