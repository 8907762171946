.papertrader-seasons-menu-container {
  min-height: fit-content;
  display: flex;
  align-items: center;
  overflow: hidden;
  max-width: 10lvw;
  padding: 5px;
  @media (max-width: 768px) {
    min-width: fit-content;
  }
  .seasons-container {
    display: flex;
    gap: 5px;
  }

  .season-container {
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    * {
      cursor: pointer;
    }

    .pulsing-dot {
      height: 5px;
      width: 5px;
      border-radius: 50%;
      opacity: 1;
      animation: pulse 6s ease-in-out infinite;
    }
  }
}
