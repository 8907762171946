.tier-badges-container {
  display: flex;
  gap: 5px;
  margin-right: auto;
  &.no-margin {
    margin-right: 0 !important;
  }
  &.compact {
    gap: 2px;
    margin-right: 0;
  }
}