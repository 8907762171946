.profile-editor-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  .input-row {
    display: flex;
    justify-content: space-evenly;
  }
}
.starter-profile-pics-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  gap: 10px;

  .starter-profile-pic {
    transition: .2s ease all;
    padding: 5px;
    border-radius: 6px;
    cursor: pointer;
    * {
      cursor: pointer;
    }
  }
}


.dropzone-container {
  display: flex;
  align-items: center;
  gap: 10px;
}