.case-popup-body {
  font-family: "BalooBhaijaan2";
  min-height: 100px;
  height: auto;
  max-height: 80lvh;
  &:focus,
  &:focus-visible {
    outline: none !important;
  }
}
.case-popup-inner {
  height: 95%;
}

.case-popup-container,
.case-popup-body,
.case-popup-wrapper {
  min-width: 600px;
  max-width: 800px;
  backdrop-filter: blur(83px);
  -webkit-backdrop-filter: blur(83px);

  background-color: transparent !important;
  * {
    background-color: transparent;
    &:focus,
    &:focus-visible {
      outline: none !important;
    }
  }
}

.case-popup-root {
  &.editor {
    margin: 2%;
  }
}

.case-popup-inner {
  height: 95%;
}
.strategy-popup-control-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .strategy-name-label {
    text-transform: capitalize;
    font-family: Montserrat-LightAlt;
    font-weight: 600;
    opacity: 0.7;
    font-size: smaller;
    padding: 3px;
  }
}

.case-control-flip-button {
  transition: 0.2s ease all;
  margin-right: auto;
  justify-content: flex-end;
  //justify-content: space-evenly;
  flex: 1;
  //min-width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  gap: 5px;
  &.compact {
    min-width: 200px;
  }

  .case-control-new-case-button,
  .case-control-case-button,
  .case-control-cases-button {
    font-size: smaller;
    transition: 0.2s ease all;
    padding: 11px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    * {
      cursor: pointer;
    }
  }
  .case-control-case-button {
    flex: 1;
    justify-content: center;
  }
}
.case-popup-header-control-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 25px;
}

.case-popup-header-control-close {
  gap: 5px;
  display: flex;
  align-items: center;
  width: 100%;
}

.case-popup-header-control-lock,
.case-popup-header-control-delete,
.case-popup-header-control-close,
.case-popup-header-control-extras,
.case-popup-header-control-extras-inner {
  transition: 0.2s ease all;
  &:hover {
    opacity: 1 !important;
  }
}

.case-popup-header-control-extras {
  opacity: 1 !important;
  height: 100%;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  max-width: 50%;
  .case-popup-header-control-extras-inner {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    * {
      cursor: pointer;
    }
    .case-popup-header-control-extras-inner-label {
      overflow: visible;
      text-overflow: ellipsis;
      height: 10px;
    }
  }
}

.chart-controls-button {
  border-radius: 6px;
  padding: 1px;
  //font-size: smaller;
  transition: 0.2s ease all;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: -moz-fit-content;
  height: fit-content;
  align-self: center;
  flex-wrap: wrap;
  max-width: 350px;
  width: fit-content;
  gap: 2px;
  .chart-controls-button-inner {
    cursor: pointer;
    * {
      cursor: pointer;
    }
    display: flex;
    align-items: center;
    gap: 2px;
    transition: 0.2s ease all;
    height: 100%;
    border: 1px solid transparent;
    display: flex;
    padding: 10px;
    min-width: 120px;
  }
}

.indicator-settings-button {
  transition: 0.2s ease all;
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 4px;
  align-items: center;
  gap: 3px;
  font-size: smaller;
  cursor: pointer;
  * {
    cursor: pointer;
  }
}

.case-button-column {
  display: flex;
  /* @media screen and (max-width: 1768px) {
    transform: translate(80px);
  } */
  flex-direction: column;
  justify-content: space-between;
}
