.menu-container,
.menu-container-small-screens {
  z-index: 99;
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 0;
  left: 0;
  height: 100vh;
  width: 4rem;
  margin: 0px;
  padding: 0px;
  &.landing-page {
    min-width: 240px;
    max-width: 240px;
    z-index: 1;
  }
}

.menu-container-small-screens {
  width: 60%;
  max-width: 240px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.invisible-overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 98;
}

.home-link-container {
  display: flex;
  width: 100%;
  height: fit-content;
  text-decoration: none;
}

.brand-container-open {
  margin-top: 4px;
  margin-bottom: 2px;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat-LightAlt";
  display: flex;
  width: 100%;
  height: 100%;
  box-shadow:
    rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;

  .span-letter-hidden-1,
  .span-letter-hidden-2,
  .span-letter-hidden-3,
  .span-letter-hidden-4,
  .span-letter-hidden-5 {
    visibility: visible;
    font-size: 46px;
  }
  .span-letter-hidden-5 {
    border-top-right-radius: 5%;
    border-bottom-right-radius: 5%;
  }
  .span-letter-special {
    font-size: 46px;
  }
}

.bottom-buttons-container {
  flex: 1;
  margin-top: 25px;
  width: 100%;
  height: 3rem;
  margin-bottom: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  * {
    cursor: pointer;
  }
  &.landing-page {
    cursor: default;
  }
  .bottom-actions-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .expand-button-content {
    border: 1px solid transparent;
    transition: 0.2s ease all;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    border-radius: 4px;
    gap: 10px;
    height: 40px;
  }
}

.brand-container {
  * {
    user-select: none;
  }
  padding-left: 2rem;
  border-radius: 5px;
  margin-top: 4px;
  margin-bottom: 2px;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease all;
  font-family: "Montserrat-LightAlt";
  display: flex;
  width: fit-content;
  height: 100%;
  transform: translateX(-23%);
  &.landing-page,
  &:hover {
    padding-left: 5.3rem;

    .span-letter-hidden-1,
    .span-letter-hidden-2,
    .span-letter-hidden-3,
    .span-letter-hidden-4,
    .span-letter-hidden-5 {
      font-size: 46px;
    }

    .span-letter-hidden-5 {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  span {
    font-size: 46px;
    font-weight: 100;
    left: 5rem;
    padding: 1px;
  }

  .span-letter-hidden-1,
  .span-letter-hidden-2,
  .span-letter-hidden-3,
  .span-letter-hidden-4,
  .span-letter-hidden-5 {
    font-size: 26px;
    transition: 0.3s ease all;
  }

  .span-letter-hidden-1 {
    transition-delay: 0.1s;
  }

  .span-letter-hidden-2 {
    transition-delay: 0.1s;
  }

  .span-letter-hidden-3 {
    transition-delay: 0.13s;
  }

  .span-letter-hidden-4 {
    transition-delay: 0.18s;
  }

  .span-letter-special {
    transition: 0.2s ease all;
    transition-delay: 0.8s;
    font-weight: 300;
  }
}

.route-container,
.route-container-with-text {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 90vh;
  align-items: center;
  text-decoration: none;
  // media query for mobile
  @media (max-width: 768px) {
    height: 80vh;
  }
}

.route-container-with-text {
  align-items: flex-start;
}

.settings-container {
  margin: 0;
  padding: 0;
  height: auto;
  border-top: 3px dotted;
  height: 10vh;
}
.link-item-active {
  filter: grayscale(0%);
  animation: toSubtleGrayscale ease-out 1s forwards;
}
.link-item {
  &.support {
    margin-top: auto;
    margin-bottom: 20px;
    opacity: 0.2;
  }
  &.submenu {
    &.icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .menu-item-tooltip {
      scale: 1.2 !important;
    }
  }
}

.link-item,
.link-item-active {
  padding: 0;
  margin: 2px;
  text-decoration: none;
  transition: all ease 0.3s;
  width: 3rem;
  height: 3rem;
  margin-top: 1rem;
  border-radius: 10%;
  // create inital animation translating grayscale from 0 to 100
  .menu-item-icon {
    transition: all ease 0.3s;
    filter: grayscale(100%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-item-tooltip {
    font-size: 16px;
    transform: scale(0);
    position: absolute;
    min-width: 125px;
    height: 30px;
    border-radius: 15px;
    padding: 6px;
    margin: 2px;
    margin-top: 0.3rem;
    left: 3.9rem;
    box-shadow:
      0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;

    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    border-radius: 30%;
    .menu-item-tooltip {
      transition: all ease 0.2s;
      //padding-left: 2rem;
      width: fit-content;
      transform: scale(1);
      animation: fade 0.2s ease 0s 1;
      font-family: "Barlow-light";
    }
  }
}

.menu-item,
.menu-item-with-text {
  transition: all ease 0.2s;
  font-size: 16px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // add media query for mobile
  @media (max-width: 768px) {
    justify-content: space-evenly;
  }
}

.menu-item-with-text {
  justify-content: flex-start;
  cursor: pointer;
  * {
    cursor: pointer;
  }

  .menu-item-text {
    margin-left: 1rem;
    text-decoration: None;
    margin-right: 15px;
    //remove underline
  }
}

#with-text {
  width: 98%;
  border-radius: 5px;
  justify-content: flex-start;
}

.settings-toggle {
  transition: all ease 0.2s;
  font-size: 16px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    font-size: 24px;
  }
}
.settings-popup {
  display: flex;
  justify-content: center;
}

.user-menu-button-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 4px;
  border-radius: 6px;
  &.open {
    flex-direction: row;
  }
  cursor: pointer;
  * {
    cursor: pointer;
  }
  .user-menu-label-container {
    display: flex;
    flex-direction: column;
    .user-menu-label {
      font-weight: 600;
    }
    .subscription-tier-menu-label,
    .user-menu-label {
      font-size: smaller;
      font-family: "Montserrat-LightAlt";
    }

  }

  .settings-button {
    gap: 10px;
    padding: 4px;
    border-radius: 5px;
    cursor: pointer;
    font-size: larger;
    transition: ease all 0.2s;
    background-color: transparent;
    border: none;
    align-items: center;
    display: flex;
    justify-content: center;
    width: fit-content;

    height: 100%;
    color: white;

    box-shadow:
      rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
}

.lottie-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.compact-menu-container {
  transition: 0.2s ease all;
  position: absolute;
  height: 80px;
  width: 80px;
  display: flex;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  justify-content: center;
  align-items: center;
  z-index: 99;
  cursor: pointer;
  * {
    cursor: pointer;
  }
  .compact-menu-label {
    font-size: 54px;
    font-family: "Montserrat-LightAlt";
  }
}

.arrow-right,
.arrow-left {
  display: flex;
  height: 30px;
}

.arrow-left {
  transform: scaleX(-1);
}

.notifications-header {
  display: flex;
  align-items: center;
  min-height: 20px;
  width: 100%;
  justify-content: space-evenly;
  
  .notification-header-button {
    transition: .2s ease all;
    cursor: pointer;
    * {
      cursor: pointer;
    }
    opacity: 0.5;
  
    &:hover, &.active {
      opacity: 1;
    }
    font-size: small;
    min-width: fit-content;
    max-width: 70%;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 2px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.notifications-button {
  margin-top: 10px;
  width: 100%;
  height: 30px;
  padding: 10px;
  border-radius: 20%;
  transition: 0.2s ease all;
  box-shadow:
    rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.notifications-popover-inner {
  padding: 4px;
  background-color: transparent;
}

.notifications-popover-inner,
.notifications-popover-target,
.notifications-popover-arrow,
.notifications-popover-popover,
.notifications-popover-wrapper,
.notifications-popover-body
 {
  background-color: transparent !important;
  backdrop-filter: blur(20px);
}

.notifications-button-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.notifications-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 204px;
  height: fit-content;
  padding: 4px;
  border-radius: 6px;
  gap: 5px;
}

.notifications-label {
  font-size: small;
  font-weight: 500;
  margin-bottom: 0.5rem;
  opacity: 0.7;
  font-family: "Barlow-light";
}

.notifications-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 5px;
  padding-top: 5px;
}

.clear-notifications-button {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 2px;
  border-radius: 10%;
  cursor: pointer;
  * {
    cursor: pointer;
  }
  transition: 0.2s ease all;
}

.submenu-divider {
  width: 100%;
  height: 20px;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0.5rem;
  opacity: 0.5;
  position: relative;
  &:hover {
    opacity: 1;
    .dimmed-label {
      &.submenu-divider-label {
        opacity: 1;
      }
    }
  }
  .dimmed-label {
    &.submenu-divider-label {
      transition: 0.2s ease all;

      font-size: smaller;
      opacity: 0;
      position: absolute;
      transform: translateY(-100%);
    }
  }
}

.email-verification-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .button-row {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
}

.application-toast-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
  .Toastify__toast-body {
    display: contents;
    align-items: center;
    justify-content: space-evenly;
  }
}

.strategy-shortcut-container {
  display: flex;
  flex-direction: column;
  display: flex;
}