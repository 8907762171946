.common-market-cap-pool-header-container {
  padding: 5px;
  margin: 2px;
  /*   box-shadow:
    rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px; */
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 5px;
  font-size: larger;
  display: flex;
  flex-direction: row;
  gap: 5px;
  min-height: 25px;
  align-items: center;
  // medium screens
  @media screen and (max-width: 1368px) {
    font-size: smaller;
  }
  .funds-pool-progress-container {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    gap: 2px;

    .common-market-cap-pool-progress {
      transition: 0.2s ease all;
      overflow: hidden;
      padding: 1px;
      display: flex;
      align-items: center;
      flex-direction: row;
      border: 1px solid transparent;
      position: relative;
      cursor: pointer;

      .common-market-cap-pool-progress-filler {
        border: 1px solid;
        height: 4px;
        width: 100%;
        display: flex;
        align-items: center;
        &.base-currency {
          height: 100%;
        }
      }
      .common-market-cap-pool-progress-tooltip {
        border-radius: 3px;
        transition: 0.2s ease all;
        position: fixed;
        min-width: 200px;
        z-index: 88;
        scale: 1;
        transform: translateY(60%);
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
        display: flex;
        flex-direction: column;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        border: 1px solid;
        .common-market-cap-pool-progress-tooltip-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 5px;
        }
      }
    }
  }
}

.pool-funds-label {
  transition: 0.2s ease all;
  padding-right: 4px;
  padding-left: 4px;
  border-radius: 3px;
  font-size: smaller;
  display: flex;
  flex-direction: column;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  &.value {
    font-family: "IBMPlexMono-Medium";
    &.dimmed {
      opacity: 0.5;
    }
  }
  justify-content: center;
  height: 100%;
}

.pool-type-indicator {
  height: 5px;
  width: 5px;
  border-radius: 50%;
}

.dimmed-label {
  &.market-cap-pool-header-label {
    transition: 0.2s ease all;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
}

.market-cap-pool-header-tooltip-container {
  display: flex;
  flex-direction: column;
}
