.custom-tooltip {
  z-index: 99;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 5px;
  .price-and-date-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
  }
  .chip-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 5px;
  }
}

.tradingcard-chart-no-data-container {
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1%;
  width: 100%;
}

.rotate-icon {
  transform: rotate(180deg);
}


.order-tooltip-labels {
  padding: 2px;
  border-radius: 4px;
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1px;
  .order-tooltip-cap {
    display: flex;
    align-items: center;
  }
  .order-tooltip-dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
  }
  .connector-line {
    height: 5px;
    width: 1px;
    background-color: blanchedalmond;
  }
  .order-tooltip-label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: x-small;
    text-transform: uppercase;
    font-weight: bold;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 24%;
    width: 30px;
    &.small{
      font-size: x-small;
    }
   
  }
}

.trading-card-chart-date-label-container {
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  min-height: 20px;
  max-height: 20px;
  align-items: flex-end;
  z-index: 98;
}