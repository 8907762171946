.backtest-button-container,
.backtest-button-container-clicked {
  align-self: center;
  padding: 5px;
  cursor: pointer;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  // disable all focus and hover effects
  &:hover,
  &:focus {
    outline: none;
  }
}

.outer_circle {
  stroke-width: 3;
  stroke-dasharray: 410;
  stroke-dashoffset: 0;
  stroke-linecap: square;
  transition: all 0.4s ease-out;
}

.backtest-button-container,
.backtest-button-container-clicked .outer_circle {
  stroke-dashoffset: 410;
  transition: stroke 0.7s 0.4s ease-out, stroke-dashoffset 0.4s ease-out;
}

.backtest-button-container-clicked .inner-circle {
  fill: transparent;
  transition: fill 0.4s 0.3s ease-out;
}

.backtest-button-container-clicked .play {
  &:hover {
    fill: white;
    transition: fill 0.4s 0.3s ease-out;
  }
}

.loader-container {
  transition: 0.2s ease all;
  align-items: center;
  justify-content: center;
}

.loader-container {
  display: flex;
}

.loader-container::before,
.loader-container::after {
  animation: cryptoPulse 3s linear infinite;
  //use sass variable for border color
  border: solid 8px var(--ring-color);
  border-radius: 9999px;
  box-sizing: border-box;
  content: " ";
  height: 100px;
  opacity: 0.4;
  position: absolute;
  transform: scale(0.714);
  width: 100px;
  z-index: 1;
}


.loader-container::after {
  animation-delay: 1s;
}

.loader-container:hover::after {
  animation-delay: 0.5s;
}

.cancel-button-container {
  cursor: pointer;
  * {
    cursor: pointer;
  }
  height: 60px;
  width: 60px;
  position: relative;
  .cancel-button-label {
    animation: fadeDisplay 0.2s ease forwards;
    z-index: 88;
    opacity: 0;
    animation-delay: 1s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: larger;
    font-weight: bold;
  }
}

