.interval-picker-container {
  width: 100%;
  height: 100%;

  .interval-picker-popup-header {
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
    font-size: larger;
    opacity: .7;
  }

  .interval-picker-popup-body {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    height: 60%;
    align-items: center;  
    .chips-container {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      align-items: flex-start;
    }
  }
  .interval-picker-popup-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.common-date-chip-outer {
  width: 120px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  max-width: 100px;
  .common-date-chip {
    transition: .2s ease all;
    display: flex;
    flex-direction: row;
    padding-left: 5px;
    padding-right: 5px;
    font-size: smaller;
    align-items: center;
    justify-content: center;
    height: 40px;
    flex: 1;

  }
  .subchips-container {
    display: flex;
    font-size: smaller;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .subchip-container {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px;
    }
  }
}
.result-date {
  width: 100%;
  display: flex;
  font-size: larger;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.interval-picker-popup-preview {
  transition: .2s ease all;
  height: 70px;
}