.simulation-info-container {
  //height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  gap: 10px;
  box-shadow:
    rgba(0, 0, 0, 0.3) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.2) 0px 18px 36px -18px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset,
    rgba(0, 0, 0, 0.06) 0px 2px 1px;
  .circular-progress-container {
    height: 76px;
    width: 76px;
  }
}

.simulation-progress-container {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.simulation-info-row {
  min-height: 30px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 10px;
  label {
    text-transform: capitalize;
    font-size: larger;
    white-space: wrap;
    &.dimmed-label {
      text-transform: none;
    }
  }
}
