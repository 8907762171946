.beta-signup-form {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
}

.form-block {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.submit-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
label {
  font-family: "BalooBhaijaan2";
}
