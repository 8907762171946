.backtester-control-bottom-container {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5px;
  
  .backtester-control-center {
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  
  .backtester-control-slot {
    padding: 3px;
    width: 120px;
  }
}