.subscriptions-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .plans-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .active-plan-container,
  .selected-plan-container {
    transition: all 0.2s ease-in-out;
    padding: 8px;
    flex: 1;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .active-plan-row {
      display: flex;
      align-items: center;
      align-items: flex-start;
      gap: 5px;
    }
  }
  .subscription-plan-container {
    max-height: 900px;
    max-width: 500px;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    user-select: none;
    * {
      user-select: none;
      cursor: pointer;
    }

    .marketing-features-container {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .feature {
        transition: 0.2s ease all;
        padding: 4px;
        border-radius: 4px;
        display: flex;
        gap: 4px;
        flex-direction: row;
        align-items: center;
        font-size: smaller;
        .feature-icon-container {
          padding: 2px;
          border-radius: 4px;
          height: 24px;
          width: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .feature-label {
          padding: 4px;
          padding-left: 8px;
          padding-right: 8px;
          border-radius: 18px;
          opacity: 0.7;
        }
      }
    }
  }
}

.subscription-plan-label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: larger;
  font-family: "Montserrat-LightAlt";
  font-weight: 500;
}

.checkout-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.payment-confirmation-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.subscription-interval-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.plan-price-label-container {
  display: flex;
  align-items: flex-end;
  margin-top: auto;

  .plan-price-label {
    font-size: 1.9rem;
    font-weight: 500;
    font-family: "Barlow-light";
  }
}

.invoices-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 400px;
  max-height: 60lvh;
  gap: 8px;

  .invoice-container {
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px;
    border-radius: 8px;
    cursor: pointer;
    * {
      cursor: pointer;
    }

    .invoice-label {
      display: flex;
      align-items: center;
      padding-left: 4px;
      padding-right: 4px;
      padding-top: 2px;
      padding-bottom: 2px;
      border-radius: 8px;
    }
  }
}

