.common-dashboard-select-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding: 5px;
  border-radius: 6px;
  gap: 4px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: larger;
  min-width: fit-content;
  transition: .2s ease all;
  max-width: 145px;
  cursor: pointer;
  * {
    cursor: pointer;
  }
}

.interval-button-label {
  font-size: small;
}
