.amount-container {
  height: inherit;
  width: 21.5vh;
  @media screen and (max-width: 1568px) {
    width: 10%;
    /* max-width: 100px;
    max-width: 10vh; */
  }
}
@media screen and (max-width: 1568px) {
  .wallet-stat-label-minor {
    opacity: 0.5;
  }
}

.wallet-amount-container {
  transition: 0.3s ease all;
  font-size: 24px;
  align-items: center;
  justify-content: flex-start;
  gap: 6%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;

  .wallet-value-container {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .initial-wallet-value-label-container {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      font-size: 14px;
    }
  }
}

.wallet-growth,
.market-change {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  align-items: center;
  font-size: smaller;

  .wallet-growth-tooltip-target {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: smaller;
  }
}

.wallet-amount-label-container {
  display: flex;
  flex-direction: column;
}
