.common-candle-size-control-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: fit-content;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 6px;
  justify-content: center;

  .upper-candle-size-control-container,
  .lower-candle-size-control-container
  {
    width: 100%;
  }

  .upper-candle-size-control-container {
    font-size: 12px;
    opacity: .6;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .lower-candle-size-control-container {
    display: flex;
    flex-direction: row;
    font-size: 13px;
    gap: 2px;
    

    
  }

}