.strategy-helper-modal-container {
  display: flex;
  flex-direction: column;
  max-height: 75lvh;
  min-height: fit-content;
  gap: 5px;

  .criteria-generation-points-container {
    padding: 4px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    gap: 4px;
    .criteria-generation-point-candle-size-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex: 1;
      border-radius: 6px;
      max-height: 150px;
      overflow-y: auto;
      transition: 0.2s ease all;
      padding: 4px;
      .criteria-generation-point-candle-size-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 4px;
      }
    }
  }

  .suggestions-container {
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 170px;
    padding: 4px;
    .suggestions-loading-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      flex-direction: column;
    }

    .indicator-suggestions {
      display: flex;
      flex-direction: column;
      padding: 2px;
      .suggestion {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        opacity: 0.8;
        transition: 0.2s ease all;
        &:hover {
          opacity: 1;
        }
        &.selected {
          opacity: 0.2;
        }
      }
    }
  }
}

.suggestion-criteria-row-chip {
  display: flex;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 6px;
  margin-left: 2px;
  margin-right: 2px;
  gap: 4px;
  opacity: 0.7;
  transition: 0.2s ease all;
  cursor: pointer;
  * {
    cursor: pointer;
  }
  &:hover,
  &.selected {
    opacity: 1;
  }
  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
    * {
      cursor: not-allowed;
    }
  }
}

.criteria-result-container {
  display: flex;
  padding: 2px;
  border-radius: 6px;
  max-height: 75px;
  overflow-y: auto;
}

.candle-size-button {
  transition: 0.2s ease all;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-width: 33px;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  * {
    user-select: none;
    cursor: pointer;
  }
}

.criteria-generator-row {
  display: flex;
  align-items: center;
  gap: 4px;
}
