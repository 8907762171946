.strategy-page-upper-row {
  display: flex;
  flex: 1;
  margin-top: .5rem;
  margin-right: .5rem;
}

.strategy-page-lower-row {
  display: flex;
  min-height: 91lvh;
  max-height: 90lvh;
}

.strategy-loading-container {
  display: flex;
  width: 100%;
  height: 100lvh;
  justify-content: center;
}

.strategy-body-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  flex: 1;

}
.loading-accent {
  position: absolute;
  height: 80px;
  width: 100px;
  left: 2%;
  top: 2%;
  filter: blur(180px);
}
.loading-accent-1 {
  position: absolute;
  height: 100px;
  width: 120px;
  right: 2%;
  bottom: 2%;
  filter: blur(200px);
}

.header-skeleton-loader {
  margin: 0.1rem;
}

.strategy-header-container {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 4.5rem;
  width: 100%;
  height: 100%;
}

.strategy-body-center-container {
  margin-left: 4.5rem;
  margin-right: 0.5rem;
  width: 100%;
  display: flex;
}
.strategy-body-side-container {
  min-height: 85.5vh;
  margin-right: 0.5rem;
  width: 20%;
}

.lower-row-loading {
  flex-direction: row;
  min-height: 91lvh;
  max-height: 90lvh;
  canvas {
    height: 88.5vh !important;
    width: 100% !important;
    position: relative !important;
  }
}
.upper-row-loading {
  display: flex;
  flex: 1;
  canvas {
    height: 12vh !important;
    width: 100% !important;
    position: relative !important;
  }
}
label {
  font-family: "BalooBhaijaan2";
}

.prompt-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .prompt-buttons-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
  }
}
