.news-marquee-container {
  flex: 1;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  gap: 20px;
  overflow: hidden;
}

.news-marquee-mover {
  animation: marquee 185s linear infinite;
  display: flex;
  align-items: center;
  gap: 20px;
  height: 100%;
  &:hover {
    animation-play-state: paused;
  }
}

@keyframes news-marquee {
  0% {
    transform: translateX(-150vw);
  }
  100% {
    transform: translateX(200vw);
  }
}

.news-marquee-item-container {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  white-space: nowrap;
  transition: 0.2s ease all;
  .news-message {
    opacity: 0.8;
    font-size: 13px;
  }
  .news-marquee-ticker {
    padding-left: 10px;
    display: flex;
    align-items: center;
    height: 100%;
    gap: 4px;
    width: 140px;
    .marquee-ticker-icon {
      height: 100%;
      display: flex;
      align-items: center;
      label {
        font-size: 20px;
      }
    }
  }
  .marquee-splitter {
    margin-left: 5px;
    border-radius: 50%;
    height: 1px;
    width: 1px;
  }
}
