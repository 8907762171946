.membership-card-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .beta-activation-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    justify-content: space-evenly;
    .beta-activation-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 15%;
      height: 100%;
      gap: 10px;
      align-items: center;
    }
  }
  .membership-container {
    display: flex;
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: space-evenly;
    gap: 5px;

    .membership-card-wrapper {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
    }

    .membership-level-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      min-width: 15%;
      flex: 1;
      height: 100%;
      label {
        text-transform: capitalize;
        font-family: "Montserrat-LightAlt";
      }
    }
  }
}

.beta-signup-label {
  transition: 0.2s ease all;
  border: 1px solid transparent;
  border-radius: 5px;
  font-size: 24px;
  min-width: 150px;
  border: 1px solid transparent;
  width: fit-content;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  &.homepage {
    margin-top: 0;
  }
}

.account-outer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.profile-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.tier-descriptions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .tier-header {
    font-size: larger;
    font-family: "Montserrat-LightAlt";
    text-transform: capitalize;
  }
  .tier-row {
    display: flex;
    align-self: center;
    gap: 10px;
    width: 100%;
    font-size: smaller;
  }
}

.enlist-for-beta-label {
  transition: 0.2s ease all;
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}

.violation-info-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
