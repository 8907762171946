.papertrader-duration-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .circular-progress-container {
    height: 50px;
    width: 50px;
  }
}

.papertrader-season-tooltip-container {
  display: flex;
  flex-direction: column;
}

.wallet-stat-label {
  &.papertrader-duration-label {
    opacity: 0.8;
  }
}