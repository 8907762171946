.homepage-container {
  padding-left: 4.5rem;
  width: 97vw;
  height: 100lvh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 0.5rem;
}

.particle-background {
  position: absolute;
  z-index: 0;
  height: 100lvh !important;
  width: 100% !important;
  overflow: hidden;
  canvas {
    height: 100lvh !important;
    width: 100lwv !important;
    position: relative !important;
  }
}

.homepage-content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  height: 99vh;
  width: 100%;
  // media queries
  @media screen and (max-width: 1262px) {
    align-items: center;
    max-width: 100%;
    padding-left: 100px;
    gap: 75px;
  }
}

.homepage-card {
  z-index: 1;
  padding: 10px;
  border-radius: 5px;
  &.transparent {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
  &.fill {
    flex: 1;
    max-width: 50vw;
    min-width: 1100px;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
}

.skewable-homepage-card {
  z-index: 2;
  border-radius: 2px;
  box-shadow:
    rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;

  // media queries
  @media screen and (max-width: 1462px) {
    left: 47vw;
  }
}
.demo-gif {
  transition: 0.2s ease all;
  position: absolute;
  opacity: 0.9;
  width: 55vw;
  // add box shadow
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.7) 0px 50px 40px -7px;
  // media queries
  @media screen and (max-width: 1600px) {
    width: 700px;
  }
}
.demo-gif-1 {
  opacity: 1;
  width: 500px;

  position: absolute;
  border-radius: 3px;

  // add box shadow
  box-shadow: rgba(0, 0, 0, 0.7) 0px 50px 40px -7px;
}
.neoton-logo-label {
  display: flex;
  transition: 0.3s ease all;
  align-items: center;
  justify-content: flex-end;
  font-family: "Montserrat-LightAlt";
  font-size: 50px;
}

.bullet-points-container {
  width: 100%;
  height: 40px;
  display: flex;
  gap: 5px;
  .bullet-point {
    cursor: pointer;
    * {
      cursor: pointer;
    }
    opacity: 0.5;
    transition: 0.2s ease all;
    padding: 5px;
    border: 1px solid transparent;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .label-container {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      gap: 5px;
      .label {
        font-family: "Montserrat-LightAlt";
        font-size: 20px;
      }
    }
  }
}

.play-pause-container {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .play-pause-icon {
    cursor: pointer;
    opacity: 0.7;
    transition: 0.2s ease all;
    &:hover {
      opacity: 1;
    }
  }
}

.homepage-header-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  .homepage-header-extra-label {
    font-family: "Barlow-thin";
    font-size: 30px;
    margin-right: 10px;
  }
}

.outer-bullet-point-text-container {
  display: flex;
  min-width: 33%;
  max-width: 50%;
  height: 50%;
  align-items: flex-start;
}

.bullet-point-container {
  border-radius: 4px;
  .bullet-point-container-header {
    font-size: xx-large;
    font-family: "Barlow-light";
  }
  padding: 20px;
  height: 100%;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  gap: 30px;

  .bullet-point-item {
    font-family: "Barlow";
    display: flex;
    align-items: center;
    gap: 25px;
    .bullet {
      font-family: "IBMPlexMono-Light";
    }
  }
}

.homepage-footer-container {
  position: absolute;
  bottom: 0;
  height: 25%;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .beta-signup-label {
    transition: 0.2s ease all;
    border: 1px solid transparent;
    border-radius: 5px;
    font-size: 30px;
    min-width: 150px;
    border: 1px solid transparent;
    width: fit-content;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: auto;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
  .rant-container {
    opacity: 0.8;
  }
}
