.common-user-stats-outer-container {
  display: flex;
  align-items: center;
  flex: 1;
  min-height: 98%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  // hide scrollbar
  scrollbar-width: none;
}
.common-user-stats-container {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-evenly;
  width: 100%;
  // media query for smaller screens
  @media (max-width: 768px) {
    flex-direction: column;
  }

  .common-user-stat-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: inherit;
    height: 100%;
    min-width: 60px;
    gap: 10px;
    position: relative;
    @media (max-width: 768px) {
      justify-content: flex-start;
    }

    .common-user-stat-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
    }

    .common-user-stat-row-chip {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
      gap: 10px;
      font-size: larger;
      padding: 6px;
      border-radius: 6px;
      label {
        font-family: Montserrat-lightAlt;
      }
      * {
        user-select: none !important;
        outline: none !important;
      }
    }
  }
}

.wl-accent {
  position: absolute;
  height: 70px;
  width: 60px;
  filter: blur(60px);
  z-index: -1;
}
