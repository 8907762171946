.common-strategy-select-container {
  display: flex;
  flex-direction: column;
  height: 99%;
  min-width: 500px;
  gap: 5px;
  min-width: fit-content;
  max-width: 800px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 5px;
  border-radius: 10px;

  .common-strategy-select-container-header {
    border-radius: 7px;

    display: flex;
    align-items: center;
    height: 50px;
    font-size: larger;
    justify-content: flex-start;
    gap: 10px;
    .dimmed-label {
      font-size: smaller;
      &.rotatable-icon {
        transform: rotate(180deg);
        // initial state should be rotated 180deg
        // after .5s, rotate back to 0deg
        animation: rotate180 0.5s ease-in-out 0.5s forwards;
      }
    }
    @media screen and (min-width: 768px) {
      //justify-content: space-between;
    }
  }

  .common-strategy-select-item-outer {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
  }

  #live-common-strategy-select-item {
    min-height: 100px;
  }

  .common-strategy-select-item {
    transition: 0.2s ease all;
    border-radius: 6px;
    cursor: pointer;
    * {
      cursor: pointer;
    }
    position: relative; // <-- Add this to establish a new positioning context for children

    padding: 5px;
    display: flex;
    min-width: 96%;
    align-items: center;
    height: 30px;
    gap: 5px;
    .common-strategy-item-name {
      min-width: 50%;
    }
    .common-strategy-version-label {
      margin-left: auto;
    }
    #hidden-indicator {
      opacity: 0 !important;
      animation: none;
    }
  }
}
.common-strategy-live-trading-indicator {
  border-radius: 50%;
  width: 5px;
  height: 5px;
  animation: pulse 8s infinite ease-in-out;
  &.absolute {
    position: absolute;
    top: 1%;
    left: 10%;
  }
}
.common-strategy-select-version-id-tooltip {
  height: 60px;
  display: flex;
  align-items: center;
}

.versions-popup-header-menu {
  padding: 5px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .versions-popup-header-menu-input {
    width: 100%;
    border: none;
    outline: none;
    box-shadow: none;
    padding: 5px;
    background-color: transparent;
    &.active-delete,
    &.active-rename {
      cursor: text;
    }
  }

  .delete-strategy-button {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: smaller;
    transition: 0.2s ease all;
    margin-left: auto;
    margin-right: 5px;
    cursor: pointer;
    * {
      cursor: pointer;
    }
  }
}
.versions-popup-select-container {
  .popup-subheader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  max-height: 40lvh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 5px;
  .versions-popup-select-item {
    border-radius: 6px;
    transition: 0.2s ease all;
    display: flex;
    align-items: center;
    min-height: 70px;
    width: 99%;
    gap: 5px;
    position: relative;
    cursor: pointer;
    * {
      cursor: pointer;
    }

    .versions-popup-select-item-button {
      transition: 0.2s ease all;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      font-size: smaller;
      min-height: 50px;
      width: 100%;
      @media (max-width: 768px) {
        font-size: x-small;
      }
    }

    .versions-popup-select-item-loader-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10%;
      .dimmed-label {
        margin-left: 5px;
        width: fit-content;
      }
    }

    .version-paperwallet-stats-container {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;

      .tier-currencies-container {
        height: 100%;
        width: fit-content;
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
        flex-direction: column;
        .upper-badges {
          display: flex;
          align-items: flex-start;
          gap: 5px;
        }
      }

      .version-paperwallet-chart-container {
        height: 100%;
        width: 190px;
        margin-left: auto;
        @media (max-width: 768px) {
          width: 40%;
        }
      }

      .change-container {
        font-size: smaller;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        width: 60px;
        margin-right: 5px;
      }
      .data-unavailable-container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        .error-icon {
          margin-left: auto;
        }
        label {
          margin-right: 5px;
        }
      }
    }
  }
}

.versions-popup-container,
.versions-popup-body,
.versions-popup-wrapper {
  min-width: 500px;
  @media (max-width: 768px) {
    min-width: 93lvw;
    max-width: 93lvw;
  }
  backdrop-filter: blur(83px);
  -webkit-backdrop-filter: blur(83px);

  background-color: transparent !important;
  * {
    background-color: transparent;
  }
}
.versions-popup-arrow {
  backdrop-filter: blur(83px);
  -webkit-backdrop-filter: blur(83px);
  background-color: transparent !important;
  * {
    background-color: transparent;
  }
}
.versions-popup-root {
  max-width: 600px;
  @media (max-width: 768px) {
    min-width: 85lvw;
  }
}
.versions-popup-target {
  @media (max-width: 768px) {
    min-width: 85lvw;
  }
}
