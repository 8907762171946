.strategy-search-result-container {
  cursor: pointer;
  transition: 0.2s ease ease-out;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  gap: 10px;
  min-height: 100px;
  min-width: 140px;
  margin: 2px;
  &:hover {
    .version-row-tier-badges-container {
      transition: 0.2s ease all;
      opacity: 1;
    }
  }

  .strategy-search-result-user-container {
    display: flex;
    align-items: center;
  }
  .strategy-search-result-strategy-container {
    display: flex;
    flex: 1;
    position: relative;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
    height: 120px;

    &.expanded {
      overflow-y: hidden;
      height: 100%;
    }

    .strategy-search-result-strategy-sort-menu {
      display: flex;
      gap: 10px;
      font-size: smaller;
      .sort-label-button {
        transition: 0.2s ease all;
        cursor: pointer;
        opacity: 0.5;
        &.selected,
        &:hover {
          opacity: 1;
        }
      }
    }

    .strategy-search-result-strategy-title {
      display: flex;
      height: fit-content;
      font-size: smaller;
      white-space: nowrap; /* Prevents the text from wrapping to the next line */
      text-overflow: ellipsis;
      text-transform: capitalize;
      overflow: hidden; /* Hides the overflowing text */
      padding: 1px;
      right: 0;
      position: absolute;
      justify-self: flex-start;
      font-family: "Montserrat-LightAlt";

      top: 0;
      opacity: 0.8;
      &.scroll {
        animation: marquee 20s linear infinite;
      }
      &.expanded,
      &.expanded-smaller {
        font-size: larger;
        font-family: "Montserrat-LightAlt";
        .query-match {
          transition: ease 0.2s all;
          font-weight: 600;
          scale: 1.02;
          * {
            opacity: 1 !important;
          }
        }
      }
      &.expanded-smaller {
        font-size: small;
      }
    }
    .strategy-search-result-strategy-versions-label {
      display: flex;
      height: fit-content;
      font-size: smaller;
      white-space: nowrap; /* Prevents the text from wrapping to the next line */
      text-overflow: ellipsis;
      overflow: hidden; /* Hides the overflowing text */
      padding: 1px;
      right: 0;
      position: absolute;
      justify-self: flex-start;
      bottom: 1%;
      opacity: 0.6;
      &.scroll {
        animation: marquee 20s linear infinite;
      }
      &.expanded {
        font-size: smaller;
        .query-match {
          transition: ease 0.2s all;
          font-weight: 600;
          scale: 1.02;
          * {
            opacity: 1 !important;
          }
        }
      }
    }
  }
}


.expanded-search-result-content {
  transition: 0.2s ease all;
  transition-delay: 0.5s;
  display: flex;
  flex-direction: column;
  height: 70%;
  align-self: flex-end;
  width: 100%;
  justify-content: center;
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y: hidden;
  &.expanded {
    overflow-y: auto;
    justify-content: flex-start;
    box-shadow:
      rgba(0, 0, 0, 0.5) 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    align-items: center;
  }
}

.strategy-search-result-strategy-version {
  display: flex;
  align-items: center;
  font-size: smaller;
  gap: 10px;
  .version-row-tier-badges-container {
    margin-left: auto;
  }
}

.search-result-paper-wallet-stats {
  transition: 0.2s ease all;
  width: 95%;
  padding: 4px;
  border-radius: 6px;
  display: flex;
  min-height: 72px;
  max-height: 72px;
  align-items: center;
  gap: 10px;
  margin-top: 3px;
  margin-bottom: 3px;
  position: relative;
  * {
    cursor: pointer;
  }
  
  .search-result-date-label {
    position: absolute;
    font-size: 12px;
    top: 0%;
    transform: translateY(-2px);
    font-weight: 600;
    left: 2px;
    opacity: 0.5;
  }

  .search-result-paper-wallet-stats-inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    .search-result-currencies-container {
      position: absolute;
      display: flex;
      gap: 2px;
      width: 100%;
      bottom: 0;
      height: 20px;
    }
    .data-unavailable-container {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .tier-currencies-container {
      height: 100%;
      width: fit-content;
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;
      flex-direction: column;
      .upper-badges {
        display: flex;
        align-items: flex-start;
        gap: 5px;
      }
    }
    .version-paperwallet-chart-container {
      height: 95%;
      align-self: flex-end;
      width: 100%;
      margin-left: auto;
      @media (max-width: 768px) {
        width: 40%;
      }
    }
  }
}

.loading-stats-container {
  height: 100%;
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
}

.strategy-result-owner-container {
  height: 100%;
  width: 160px;
  padding: 10px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  .selected-versions-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}

.strategy-result-owner-footer-container {
  transform: translateY(10px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  .strategy-delete-confirmation-container {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
