.error-page-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  align-items: center;
  justify-content: center;
  gap: 20px;
  .error-header {
    font-family: Montserrat-LightAlt;
  }
}