.microtest-chart-container {
  display: flex;
  flex-direction: column;
  height: 190px;
  width: 100%;
  position: relative;
  .loading-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    .dimmed-label {
      align-self: center;
    }
  }
}

.legend-row {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}