.compact-wallet-value-container {
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  .compact-wallet-value-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;    
    .compact-wallet-value-row {
      display: flex;
      flex-direction: row;
      font-size: smaller;
      align-items: center;
      gap: 5px;
    }
  }
  .compact-wallet-change-container {
    height: 100%;
    width: 100%;
    display: flex;
    margin-left: auto;
  }  
}
