.outer-user-profile-container {
  display: flex;
  &:hover {
    .user-profile-container {
      transform: translateY(-5%);
    }
  }
  .user-profile-container {
    //max-width: fit-content;
    &.clickable {
      cursor: pointer;
      * {
        cursor: pointer;
      }
      &:hover {
        transform: translateY(-5%);
      }
    }
    transition: 0.2s ease all;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    box-shadow:
      rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px,
      rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px,
      rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 30%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;

    &:hover {
      .neoton-divider {
        width: 50%;
        filter: grayscale(100%);
        opacity: 0.6;
      }
    }
    .neoton-divider {
      transition: 0.2s ease all;
    }

    .username-label {
      white-space: nowrap; /* Prevents the text from wrapping to the next line */
      overflow: hidden; /* Hides the overflowing text */
      text-overflow: ellipsis; /* Shows ellipsis when the text overflows */
      max-width: 70%; /* Ensures the container doesn't grow beyond its parent */
      font-size: smaller;
      align-self: center;
      &.prominent {
        opacity: 1;
      }
      &.dimmed {
        opacity: 0.6;
      }
    }
  }
}
.user-menu-label {
  font-size: smaller;
  &.user,
  &.owner {
    opacity: 0.6;
    font-size: smaller;
  }
}

#tiny-profile {
  position: relative;
  border-radius: 50%;
  .tiny-user-label-container {
    display: flex;
    overflow: visible;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 60px;
    position: absolute;
    overflow: hidden;
    border-radius: 2px;
    opacity: 0;
    box-shadow:
      rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;

    .tiny-user-label {
      transition: 0.2s ease all;
      position: absolute;
      z-index: 99;
      opacity: 0;
      border-radius: 5px;
      backdrop-filter: blur(2px);
      -webkit-backdrop-filter: blur(2px);
      white-space: nowrap;
    }
  }
  &:hover {
    .tiny-user-label-container {
      opacity: 1;
      .tiny-user-label {
        opacity: 1;
      }
    }
  }
}
