.case-control-container {
  width: 100%;
  height: 98%;
  display: flex;
  flex-direction: column;
  max-width: 600px;

  .case-control-scrollable-body {
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
  }

  .case-control-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    gap: 5px;
    padding: 4px;
  }

  .target-currencies-container {
    display: flex;
    flex-direction: column;
    position: relative;
    .target-currencies-selection-container,
    .target-currencies-selection-container-empty {
      min-height: 30px;
      transition: 0.2s ease all;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      padding: 5px;
      gap: 5px;
      flex-wrap: wrap;
      cursor: pointer;
      * {
        cursor: pointer;
      }
    }
  }
  .target-currencies-selection-container-empty {
    animation: grayscaleAnimation 2.5s ease-in-out infinite;
  }
}

.new-currency-selection-container {
  display: flex;
  flex-direction: row;
  height: fit-content;
  flex-wrap: wrap;
  gap: 4px;
  font-family: "BalooBhaijaan2";
  font-size: smaller;
  justify-content: space-evenly;

  .new-currency-selection-item {
    cursor: pointer;
    * {
      cursor: pointer;
    }
    transition: 0.2s ease all;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: 50px;
    width: fit-content;
    align-items: center;
    justify-content: center;
    padding: 8px;
  }
}
.case-extras-outer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  align-items: center;
  .case-extras-container {
    min-height: 180px;
    width: 99%;
    display: flex;
    gap: 5px;
    &.order-options {
      width: 99%;
      min-height: 50px;
    }
    .extras-column {
      border-radius: 4px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .extras-column-header {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 3px;
      }
      .extras-column-body {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3px;
        &.order-options {
          flex-direction: row;
          justify-content: space-evenly;
        }
        .immediate-action-input-container {
          display: flex;
          height: 100%;
          .immediate-action-column {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }
        .reaction-time-container {
          width: 90%;
          display: flex;
          margin-top: auto;
          flex-direction: column;
          align-items: center;
        }

        .wallet-stat-label-profit {
          font-size: larger;
        }
      }
    }
    .number-input-column {
      display: flex;
      flex-direction: column;
    }
    .number-input-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 2px;
      user-select: none;
      * {
        user-select: none;
      }
      .extras-number-input {
        font-size: larger;
        border-radius: 4px;
        width: 45px;
        height: 22px;
        font-family: "IBMPlexMono-Medium";
        text-align: center;

        outline: none;
        transition: border-color 0.3s;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
    .extras-slider-container {
      width: 40%;
      .slider-tooltip-label {
        font-family: "BalooBhaijaan2";
        backdrop-filter: blur(25px);
        -webkit-backdrop-filter: blur(25px);
      }
      &:hover {
        .column-label {
          transition: 0.2s ease all;
          opacity: 0.5;
        }
      }
    }
  }
}

.order-options {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.immediate-action-popover-menu {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.dimmed-label {
  white-space: nowrap;
  user-select: none;
}

.equation-wrapper {
  user-select: none;
  font-size: smaller;
}

.immediate-action-input-controls {
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 60px;
  display: flex;
  position: relative;
  .immediate-action-input-button {
    transition: 0.2s ease all;
    position: absolute;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    cursor: pointer;
    &.arrow-up {
      transform: translateY(-100%);
      &:hover {
        opacity: 1 !important;
      }
    }
    &.arrow-down {
      transform: translateY(100%);
      &:hover {
        opacity: 1 !important;
      }
    }
  }
  &:hover {
    .immediate-action-input-button {
      opacity: 0.5;
    }
  }
}

.more-order-options-container {
  gap: 2px;
  padding: 4px;
  min-width: 100px;
  display: flex;
  flex-direction: column;
}
