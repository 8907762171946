.public-user-profile-container {
  transition: 0.2s ease all;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}

.user-report-confirmation-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 10px;
  .user-report-confirmation-form-row {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    padding: 5px;
  }
}

.public-user-profile-stats-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
