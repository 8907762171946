.livetrader-logs {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  max-height: 98%;
  height: 100%;
  overflow: hidden;
  &.detached {
    min-height: 600px;
  }
  .livetrader-logs-header {
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    justify-content: space-between;
    .sort-method-label {
      cursor: pointer;
      transition: 0.2s ease all;
      margin-left: 5px;
    }
    .dimmed-label {
      margin-right: 5px;
    }
  }
  .livetrader-log-container {
    display: flex;
    flex: 1;
    overflow: hidden;
    
    .log-item {
      border-radius: 4px;
      display: flex;
      max-width: 390px;
      flex-direction: column;
      gap: 4px;
      .log-item-header {
        height: 30px;
        display: flex;
        align-items: center;
        padding: 4px;
      }
      .log-message {
        white-space: wrap;
        padding: 4px;
      }
    }
  }
}

.livetrader-error-details-container {
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  align-items: center;
  gap: 5px;
  .log-evaluation-container {
    padding: 10px;
    border-radius: 6px;
  }
}
