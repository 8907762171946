.compact-trading-overview-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .compact-market-cap-pool-header-container {
    padding: 5px;
    font-size: smaller;
    display: flex;
    flex-direction: row;
    gap: 5px;
    min-height: 30px;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  .compact-market-cap-pool-container {
    display: flex;
    gap: 5px;
    height: fit-content;
    flex-direction: column;
    flex: 1;
    margin-bottom: 5px;
  }
  .pool-currency-array-container {
    margin-left: auto;
  }
}
