.no-data-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wallet-value-chart-container-inner {
  width: 100%;
  display: flex;
  height: 100%;
}

.wallet-chart-button {
  cursor: pointer;
  position: absolute;
  top: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  gap: 10px;
  transition: .2s ease all;
  opacity: .5;
  &:hover {
    opacity: 1;
  }
}