.common-trading-card-container {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin: 4px;
  position: relative;
  overflow: hidden;
  &:hover {
    .currency-theme-color {
      opacity: 0.4;
      filter: blur(96px);
    }
  }

  .currency-theme-color {
    transition: 0.3s ease-in-out;
    transition-delay: 0.1s;
    height: 70px;
    width: 70px;
    position: absolute;
    z-index: 0;
    top: 10%;
    left: 0%;
    opacity: 0.13;
    filter: blur(66px);
    &.right {
      left: 95%;
      top: 90%;
    }
    &.holding {
      opacity: 0.4;
      left: 96%;
      top: 1%;
      height: 40px;
      filter: blur(66px);
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .common-trading-card-row {
    position: relative;
    border-radius: 10px;
    min-height: 20%;
    display: flex;
    flex-direction: row;
    &.header {
      max-height: 30px;
      min-height: 20px;
      .holding-indicator-badge {
        margin-left: auto;
        margin-right: 5px;
        height: 15px;
        align-self: center;
        padding: 2px;
        text-transform: uppercase;
        font-size: smaller;
        border-radius: 16%;
        font-weight: 600;
        display: flex;
        align-items: center;
        user-select: none;
        transition: 0.2s ease all;
        opacity: 0;
        font-family: "BalooBhaijaan2";

        &.active {
          opacity: 1;
        }
      }
    }
    &.center {
      flex: 1;
    }

    .common-trading-icon-button-row {
      display: flex;
      flex-direction: row;
      gap: 4px;
      opacity: 0.7;
      transition: 0.2s ease all;
      &:hover {
        opacity: 1;
      }
    }
    .common-trading-card-row-cell {
      display: flex;
      position: relative;
      padding: 5px;
      &.chart {
        flex: 1;
        flex-direction: column;
      }
      &.currency {
        overflow: hidden;
        height: fit-content;
      }
      &.immediate-action-indicator {
        border: 1px solid orange;
        min-width: 40px;
      }
      &.action-indicator {
        padding: 10px;
      }
      &.action-indicator {
        flex-direction: column;
        gap: 15px;
        justify-content: flex-end;
      }
    }
  }
}

.new-action-indicator-container {
  width: 30px;
  height: 100%;
  min-height: 100px;
  max-height: 100px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
  align-items: center;
  padding-right: 4px;

  .new-action-indicator-bar {
    width: 95%;
    border-radius: 3px;
    height: 4px;
  }
}

.current-holding-details-container {
  display: flex;
  align-items: center;
  gap: 5px;
  flex: 1;
  justify-content: flex-end;
  padding-left: 5px;
  padding-right: 5px;
  user-select: none;
  * {
    user-select: none;
  }

  .current-holding-details-block {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 100%;
    padding-left: 5px;
    transition: 0.2s ease all;
    cursor: pointer;
    * {
      cursor: pointer;
    }
  }
}

.focused-order-popup {
  align-self: center;
  padding: 4px;
  left: 140px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  backdrop-filter: blur(4px);
  gap: 4px;
  flex-wrap: wrap;
  justify-self: center;
}
