.new-strategy-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  
}

.common-strategy-select-container {
  max-height: 250px;
  // media queries
  max-width: none !important;
  @media screen and (max-width: 768px) {
    max-height: none;
    max-width: 400px;
  }
}
.new-strategy-content-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
  .starter-template-container {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .blank-template-container {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px;
    border-radius: 6px;
  }
}