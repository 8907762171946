/* styles.css */
.privacy-policy-container, .tos-container, .about-container {
  max-width: 800px;
  overflow-y: auto;
  overflow-x: hidden;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

p {
  margin-bottom: 16px;
}

ul {
  margin-bottom: 16px;
}

li {
  margin-bottom: 8px;
  // hide bullets
  list-style-type: none;
}

section {
  padding: 20px;
}
